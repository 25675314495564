import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="trix"
export default class extends Controller {
  static targets = ['editor', 'foregroundColorPicker', 'backgroundColorPicker']

  openForegroundColorPicker() {
    this.foregroundColorPickerTarget.click()
  }

  openBackgroundColorPicker() {
    this.backgroundColorPickerTarget.click()
  }

  foregroundColorChanged() {
    this.editorTarget.editor.activateAttribute('foregroundColor', this.foregroundColorPickerTarget.value)
  }

  backgroundColorChanged() {
    this.editorTarget.editor.activateAttribute('backgroundColor', this.backgroundColorPickerTarget.value)
  }
}
