import { Controller } from "@hotwired/stimulus"
import { DIACRITICS } from "../../constants"

// Connects to data-controller="transcriptions--actions"
export default class extends Controller {
  static targets = ["content", "diacritizationTrigger", "copyTrigger"]
  diacritized = null
  worker = null

  connect() {
    this.diacritized = Array(this.contentTargets.length).fill("not-diacritized")
    this.worker = new Worker("/workers/diacritizationWorker.js")

    this.worker.onmessage = (event) => {
      const { type, text } = event.data

      if (type === "diacritizedText") {
        this.#handleDiacritizedText(text)
      }
    }
  }

  diacritize(event) {
    const cueIndex = event.currentTarget.dataset.index
    const contentTarget = this.contentTargets[cueIndex]
    const diacritizationTriggerTarget = this.diacritizationTriggerTargets[cueIndex]

    const text = contentTarget.childNodes[0].textContent.trim()

    if (this.diacritized[cueIndex] === "not-diacritized") {
      this.diacritized[cueIndex] = "diacritizing"

      this.#loadingTriggerIcon(diacritizationTriggerTarget)

      this.worker.postMessage({ type: "diacritizeText", text })
    } else if (this.diacritized[cueIndex] === "diacritized") {
      contentTarget.childNodes[0].textContent = `${this.#removeDiacritics(text)} `

      this.diacritized[cueIndex] = "not-diacritized"

      this.#diacritizedTriggerIcon(diacritizationTriggerTarget)
    }
  }

  copy(event) {
    const cueIndex = event.currentTarget.dataset.index
    const contentTarget = this.contentTargets[cueIndex]
    const copyTriggerTarget = this.copyTriggerTargets[cueIndex]

    const originalContent = copyTriggerTarget.innerHTML

    copyTriggerTarget.innerHTML = `
      <div class="bg-lime-700 rounded-full p-px text-white">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 max-w-3.5" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z" clip-rule="evenodd"></path>
        </svg>
      </div>
    `

    setTimeout(() => {
      copyTriggerTarget.innerHTML = originalContent
    }, 1000)

    const text = contentTarget.childNodes[0].textContent.trim()

    navigator.clipboard.writeText(text)
  }

  #handleDiacritizedText(text) {
    const cueIndex = this.diacritized.findIndex((state) => state === "diacritizing")
    if (cueIndex === -1) return

    const contentTarget = this.contentTargets[cueIndex]
    const diacritizationTriggerTarget = this.diacritizationTriggerTargets[cueIndex]

    contentTarget.childNodes[0].textContent = `${text} `
    this.diacritized[cueIndex] = "diacritized"

    this.#undiacritizedTriggerIcon(diacritizationTriggerTarget)
  }

  #removeDiacritics(text) {
    return text.replace(new RegExp(`[${DIACRITICS.join("")}]`, "g"), "")
  }

  #loadingTriggerIcon(diacritizationTriggerTarget) {
    diacritizationTriggerTarget.innerHTML = `
      <svg class="animate-spin h-4 max-w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    `
  }

  #undiacritizedTriggerIcon(diacritizationTriggerTarget) {
    diacritizationTriggerTarget.textContent = "ض"
  }

  #diacritizedTriggerIcon(diacritizationTriggerTarget) {
    diacritizationTriggerTarget.textContent = "ضَ"
  }
}
