const FATHA = 'َ'
const DAMMA = 'ُ'
const KASRA = 'ِ'
const FATHATAN = 'ً'
const DAMMATAN = 'ٌ'
const KASRATAN = 'ٍ'
const SHADDA = 'ّ'
const SUKUN = 'ْ'

export const DIACRITICS = [FATHA, DAMMA, KASRA, FATHATAN, DAMMATAN, KASRATAN, SHADDA, SUKUN]
