// Entry point for the build script in your package.json
import * as Turbo from "@hotwired/turbo"

import TurboPower from "turbo_power"
TurboPower.initialize(Turbo.StreamActions)

import "@hotwired/turbo-rails"
import "./controllers"

import "@rails/actiontext"

import "./actiontext"
import "./custom_turbo_events"
import gtag from "./google_analytics"
import "./local_time_settings"
import "./popstate_override"

window.toggleNavBar = () => {
  if (document.querySelectorAll("[data-controller='ruby-ui--sheet-content']").length != 0) {
    document.querySelector("[data-action='click->ruby-ui--sheet-content#close']").click()
  } else {
    document.querySelector("[data-action='click->ruby-ui--sheet#open']").click()
  }
}
